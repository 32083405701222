.auth-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .auth-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .input-group label {
    display: block;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border:solid grey 1px;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    border: solid black 1px
  }
  
  button:disabled {
    background-color: #ccc;
  }